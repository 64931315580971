import React, { Component, Fragment } from "react"
import Link from "../utils/link"

import Logo from "../assets/images/logo.svg"
import RegisterHeader from "./register-header"
import { Dialog, Transition } from "@headlessui/react"
import Cookies from 'js-cookie'

class Header extends Component {
  state = {
    offCanvas: false,
    scrolled: false,
    formActive: true,
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollDetect)

    this.setState({ formActive: Cookies.get('register_dialog') !== 'hide' })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollDetect)
  }

  onScroll = (e) => {
    this.setState({
      scrolled: window.scrollY > 1,
    })
  }

  scrollDetect = () => {
    window.clearTimeout(this.isScrolling)
    this.isScrolling = setTimeout(this.onScroll, 0)
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  _toggleForm = () => {
    let { formActive } = this.state
    formActive = !formActive
    this.setState({ formActive })

    if (!formActive) {
      Cookies.set('register_dialog', 'hide')
    }
  }

  render() {
    let { offCanvas, scrolled, formActive } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: "active",
    }

    let headerClass = "header"
    if (scrolled) headerClass += " header--scrolled"

    return (
      <>
        <header className={headerClass}>
          <div className="header__inner">
            <Link
              to="/"
              title="Found Developments"
              className="header__logo"
              {...props}
            >
              <img src={Logo} alt="Found Developments" />
            </Link>
            <nav className="header__nav">
              <ul>
                <li>
                  <button className="btn" onClick={() => this._toggleForm()}>
                    Register Now
                  </button>
                </li>
                <li>
                  <button
                    onClick={this._toggleOffCanvas}
                    className={
                      offCanvas
                        ? "header__hamburger active"
                        : "header__hamburger"
                    }
                  >
                    <span className="lines"></span>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div className={`off-canvas ${offCanvas && "off-canvas--active"}`}>
          <div className="off-canvas__inner">
            <nav className="off-canvas__nav">
              <ul>
                <li>
                  <Link to="/#ecomm-studios" {...props}>
                    Sophisticated E-Commerce Workspaces
                  </Link>
                </li>
                <li>
                  <Link to="/#storage-spaces" {...props}>
                    Storage Spaces
                  </Link>
                </li>
                <li>
                  <Link to="/#location-culture" {...props}>
                    Location and Culture
                  </Link>
                </li>
                <li>
                  <Link to="/#key-features" {...props}>
                    Key Features
                  </Link>
                </li>
                <li>
                  <Link to="/#team" {...props}>
                    Team
                  </Link>
                </li>
                <li>
                  <Link to="/about" {...props}>
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/contact" {...props}>
                    Contact
                  </Link>
                </li>
              </ul>
            </nav>
            <div className="off-canvas__social">
              <ul>
                <li>
                  <Link to="https://www.instagram.com/found.huntingdale/">
                    Instagram
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <Transition appear show={formActive} as={Fragment}>
          <Dialog
            as="div"
            open={formActive}
            className="tw-relative tw-z-10"
            onClose={() => this._toggleForm()}
          >
            <Transition.Child
              as={Fragment}
              enter="tw-transition-opacity tw-ease-out tw-duration-300"
              enterFrom="tw-opacity-0"
              enterTo="tw-opacity-100"
              leave="tw-transition-opacity tw-ease-in tw-duration-200"
              leaveFrom="tw-opacity-100"
              leaveTo="tw-opacity-0"
            >
              <div
                className="tw-fixed tw-inset-0 tw-bg-black/40"
                aria-hidden="true"
              />
            </Transition.Child>

            <div className="tw-fixed tw-inset-0 tw-overflow-y-auto">
              <div className="tw-flex tw-min-h-full tw-items-center tw-justify-center tw-text-center">
                <Transition.Child
                  as={Fragment}
                  enter="tw-ease-out tw-duration-300"
                  enterFrom="tw-opacity-0 tw-scale-95"
                  enterTo="tw-opacity-100 tw-scale-100"
                  leave="tw-ease-in tw-duration-200"
                  leaveFrom="tw-opacity-100 tw-scale-100"
                  leaveTo="tw-opacity-0 tw-scale-95"
                >
                  <Dialog.Panel className="tw-self-stretch sm:tw-self-auto tw-w-full tw-max-w-[550px] lg:tw-max-w-[1000px] tw-overflow-hidden tw-bg-black tw-text-white tw-px-6 tw-py-8 md:tw-px-8 md:py-8 lg:tw-px-16 lg:tw-py-11 tw-text-left tw-align-middle tw-shadow-xl tw-transition-all tw-relative">
                    <button type="button" className="tw-absolute tw-right-6 tw-top-6 md:tw-right-8 md:tw-top-8 lg:tw-right-10 lg:tw-top-10" onClick={() => this._toggleForm()}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="tw-w-5 tw-h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                      <span className="tw-sr-only">Close</span>
                    </button>

                    <p className="tw-text-[13px] tw-mb-2">
                      CONTRUCTION COMMENCED | C3 CONSTRUCTION GROUP
                    </p>
                    <Dialog.Title as="h2" className="tw-text-base tw-leading-6">
                      Register Your Interest.
                    </Dialog.Title>
                    <RegisterHeader slug="huntingdale" title={"Huntingdale"} />
                  </Dialog.Panel>
                </Transition.Child>

              </div>
            </div>
          </Dialog>
        </Transition>
      </>
    )
  }
}

export default Header
